<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create New Country</v-card-title>
    <v-row>
      <v-col cols="6"><v-text-field dense outlined label="Name" v-model="item.name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="CountryCode" v-model="item.country_code"/></v-col>
</v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CreateCountry',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
      createItem: 'country/create'
    }),
    processAdd() {
      this.createItem(this.item).then(() => {
        this.onCreated();
      });
    }
  }
}
</script>
